import React, { useState, useRef, useEffect } from "react"; 
import { Link, navigate } from "gatsby";
import { useLocation } from '@reach/router'; 

import Layout from "../components/layout/layout";
import Seo from "../components/seo";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, FormHelperText, withWidth } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import JoyButton from '@mui/joy/Button';

import "./normalize.css";
import './contactform.scss';

import Footer from "../components/footer/footer";
import Contact from "../components/contact/contact";

import ImgLogo from "../images/logo-tanecni-zlonin.svg";

const Content = () => {
    const location = useLocation();

    const [formData, setFormData] = useState({
        courseType: "",
        familyName: "",
        firstName: "",
        birthDate: "",
        idNumber: "",
        parentPhone: "",
        parentEmail: "",
        address: "",
        healthIssues: "",
        notes: "",
        schoolYear: "2024/2025",
        consentGiven: false,
    });

    const [fieldErrors, setFieldErrors] = useState({
        courseType: false,
        familyName: false,
        firstName: false,
        birthDate: false,
        idNumber: false,
        parentPhone: false,
        parentEmail: false,
        address: false,
    });

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);

    const courseTypeRef = useRef(null);
    const nameRef = useRef(null);
    const firstNameRef = useRef(null);
    const birthDateRef = useRef(null);
    const idNumberRef = useRef(null);
    const parentPhoneRef = useRef(null);
    const parentEmailRef = useRef(null);
    const addressRef = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [open, setOpen] = useState(false);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#333333',
            },
            secondary: {
                main: '#783bdc',
            },
        },
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pripravka = queryParams.get("pripravka");
        const kurz = queryParams.get("kurz");

        if (pripravka !== null) {
            setFormData((prevData) => ({
                ...prevData,
                courseType: "Taneční přípravka 5-6 let",
            }));
        } else if (kurz !== null) {
            setFormData((prevData) => ({
                ...prevData,
                courseType: "Taneční kurz 7-12 let",
            }));
        }
    }, [location.search]);

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;
        return phoneRegex.test(phone);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateBirthDate = (date) => {
        const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
        return dateRegex.test(date);
    };

    const formatBirthDate = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        const originalLength = formData.birthDate.length;
    
        // Check if the user is deleting
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            if (originalLength === 6 || originalLength === 3) {
                // If deleting just after a dot, remove the dot and the preceding digit
                value = value.slice(0, -1);
            }
        }
    
        // Automatically add dots after day and month
        if (value.length > 1) {
            value = value.slice(0, 2) + '.' + value.slice(2);
        }
        if (value.length > 4) {
            value = value.slice(0, 5) + '.' + value.slice(5);
        }
    
        // Limit to 10 characters (dd.mm.yyyy)
        if (value.length > 10) {
            value = value.slice(0, 10);
        }
    
        setFormData({
            ...formData,
            birthDate: value,
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (fieldErrors[name]) {
            setFieldErrors({
                ...fieldErrors,
                [name]: false,
            });
        }
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setIsCheckboxChecked(isChecked);
        setFormData({
            ...formData,
            consentGiven: isChecked,
        });

        if (isChecked) {
            setCheckboxError(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const idNumberRegex = /^[0-9\s/]+$/;
    
        const newErrors = {
            courseType: !formData.courseType,
            familyName: formData.familyName.length < 3,
            firstName: formData.firstName.length < 3,
            birthDate: !validateBirthDate(formData.birthDate), // Validate birth date format
            idNumber: !idNumberRegex.test(formData.idNumber),
            parentPhone: !validatePhoneNumber(formData.parentPhone),
            parentEmail: !validateEmail(formData.parentEmail),
            address: formData.address.length < 10,
        };
    
        setFieldErrors(newErrors);
    
        const hasFieldErrors = Object.values(newErrors).some(error => error);
    
        if (hasFieldErrors) {
            const firstErrorField = Object.keys(newErrors).find(key => newErrors[key]);
            switch (firstErrorField) {
                case 'courseType':
                    courseTypeRef.current.focus();
                    break;
                case 'familyName':
                    nameRef.current.focus();
                    break;
                case 'firstName':
                    firstNameRef.current.focus();
                    break;
                case 'birthDate':
                    birthDateRef.current.focus();
                    break;
                case 'idNumber':
                    idNumberRef.current.focus();
                    break;
                case 'parentPhone':
                    parentPhoneRef.current.focus();
                    break;
                case 'parentEmail':
                    parentEmailRef.current.focus();
                    break;
                case 'address':
                    addressRef.current.focus();
                    break;
                default:
                    break;
            }
            return;
        }
    
        if (!isCheckboxChecked) {
            setCheckboxError(true);
            return;
        }
    
        setIsSubmitting(true);
        setSubmitError(null);

        try {
            const response = await fetch('https://swanlake.tanecni-zlonin.cz/api/registration-kids', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Something went wrong');
            }

            setSubmitSuccess(true);
            setOpen(true);
            setFormData({
                courseType: "",
                familyName: "",
                firstName: "",
                birthDate: "",
                idNumber: "",
                parentPhone: "",
                parentEmail: "",
                address: "",
                healthIssues: "",
                notes: "",
                schoolYear: "2024/2025",
                consentGiven: false,
            });
            setIsCheckboxChecked(false);

        } catch (error) {
            setSubmitError(error.message);
            console.error("Submission error:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        navigate('/');
    };

    return (
        <Layout>
            <Seo title="Přihláška dětí do tanečního kurzu" />

            <div className="tz-contactform">
                <Container className="pt-5 pb-5">
                    <Link to="/" data-sal="fade" data-sal-duration="1000">
                        <img src={ImgLogo} alt="Taneční Zlonín logo" className="tz-miniheader__logo" />
                    </Link>
                    <h1 className="tz-miniheader__h1 tz-color-black mb-5" data-sal="fade" data-sal-duration="1000">
                        Přihláška dětí do&nbsp;tanečního kurzu <span className="tz-miniheader__h1--small">pro školní rok 2024/2025</span>
                    </h1>
                    <Row>
                        <Col xs="12" lg="6" className="d-flex gap-3">
                            <form className="tz-contactform__form" onSubmit={handleSubmit} noValidate>
                                <ThemeProvider theme={theme}>
                                    <FormControl data-sal="fade" data-sal-duration="1000" fullWidth margin="normal" variant="outlined" error={fieldErrors.courseType}>
                                        <InputLabel id="course-type-label">Vyberte kurz</InputLabel>
                                        <Select
                                            labelId="course-type-label"
                                            id="courseType"
                                            name="courseType"
                                            value={formData.courseType}
                                            onChange={handleInputChange}
                                            label="Vyberte kurz"
                                            inputRef={courseTypeRef}
                                            required
                                        >
                                            <MenuItem value={"Taneční přípravka 5-6 let"}>Taneční přípravka 5-6 let</MenuItem>
                                            <MenuItem value={"Taneční kurz 7-12 let"}>Taneční kurz 7-12 let</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField  data-sal="fade" data-sal-duration="1000"
                                        inputRef={nameRef}
                                        required
                                        label="Příjmení dítěte"
                                        name="familyName"
                                        error={fieldErrors.familyName}
                                        helperText={fieldErrors.familyName ? "Příjmení musí mít alespoň 3 znaky." : ""}
                                        value={formData.familyName}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        inputRef={firstNameRef}
                                        required
                                        label="Jméno dítěte"
                                        name="firstName"
                                        error={fieldErrors.firstName}
                                        helperText={fieldErrors.firstName ? "Jméno musí mít alespoň 3 znaky." : ""}
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        inputRef={birthDateRef}
                                        required
                                        label="Datum narození (dd.mm.rrrr)"
                                        name="birthDate"
                                        error={fieldErrors.birthDate}
                                        helperText={fieldErrors.birthDate ? "Prosím, zadejte datum ve formátu dd.mm.rrrr." : ""}
                                        value={formData.birthDate}
                                        onChange={formatBirthDate} // Change handler to formatBirthDate
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="dd.mm.rrrr"
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        inputRef={idNumberRef}
                                        required
                                        label="Rodné číslo"
                                        name="idNumber"
                                        error={fieldErrors.idNumber}
                                        helperText={fieldErrors.idNumber ? "Zadejte rodné číslo ve formátu 1234567890" : ""}
                                        value={formData.idNumber}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        inputRef={parentPhoneRef}
                                        required
                                        label="Telefon na rodiče"
                                        name="parentPhone"
                                        type="tel"
                                        error={fieldErrors.parentPhone}
                                        helperText={fieldErrors.parentPhone ? "Prosím, vyplňte platné telefonní číslo." : ""}
                                        value={formData.parentPhone}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        inputRef={parentEmailRef}
                                        required
                                        label="Email na rodiče"
                                        name="parentEmail"
                                        type="email"
                                        error={fieldErrors.parentEmail}
                                        helperText={fieldErrors.parentEmail ? "Prosím, vyplňte platný email." : ""}
                                        value={formData.parentEmail}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        inputRef={addressRef}
                                        required
                                        label="Adresa bydliště"
                                        name="address"
                                        error={fieldErrors.address}
                                        helperText={fieldErrors.address ? "Adresa musí mít alespoň 10 znaků." : ""}
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        label="Případné známé zdravotní potíže"
                                        name="healthIssues"
                                        value={formData.healthIssues}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                    />
                                    <TextField data-sal="fade" data-sal-duration="1000"
                                        label="Poznámka, jiná sdělení"
                                        name="notes"
                                        value={formData.notes}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                    />
                                    <TextField
                                        type="hidden"
                                        name="schoolYear"
                                        value={formData.schoolYear}
                                    />
                                    <FormControl error={checkboxError} required component="fieldset" margin="normal" data-sal="fade" data-sal-duration="1000">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isCheckboxChecked}
                                                    onChange={handleCheckboxChange}
                                                    name="consent"
                                                    sx={{
                                                        color: '#783bdc',
                                                        '&.Mui-checked': {
                                                            color: '#783bdc',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Souhlasím s tím, aby Taneční Zlonín&nbsp;z.s. zpracovával a&nbsp;evidoval mé osobní údaje a&nbsp;údaje dítěte poskytnuté v&nbsp;souvislosti s&nbsp;členstvím dítěte a&nbsp;činností v&nbsp;Taneční Zlonín&nbsp;z.s."
                                        />
                                        {checkboxError && <FormHelperText>Prosíme, udělte nám tento souhlas. Jinak to nepůjde 🚫</FormHelperText>}
                                    </FormControl>
                                    {submitError && (
                                        <Typography color="error" style={{ marginBottom: '1rem' }}>
                                            {submitError}
                                        </Typography>
                                    )}
                                    <Button 
                                        className={`mt1 mb3 tz-contactform__button ${submitSuccess ? 'tz-contactform__button--wide' : ''}`}
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}>
                                        {isSubmitting ? (
                                            <CircularProgress style={{ color: '#fff' }} size={24} />
                                        ) : submitSuccess ? (
                                            <span>
                                                <FontAwesomeIcon icon={faRocket} /> úspěšně odesláno
                                            </span>
                                        ) : (
                                            'Odeslat'
                                        )}
                                    </Button>
                                </ThemeProvider>
                            </form>
                        </Col>
                        <Col xs="12" lg={{ span: 5, offset: 1 }}>
                            <h4 className="text-black mt1" data-sal="fade" data-sal-duration="1000">Poskytovatel:</h4>
                            <p data-sal="fade" data-sal-duration="1000">
                                <strong>Taneční Zlonín z.s.</strong><br />
                                U Golfu 145/4<br />
                                250 64  Zlonín<br />
                                IČO: 11964570
                            </p>
                            <p className="text-small" data-sal="fade" data-sal-duration="1000">
                                a člen v přihlášce (resp. zákonný zástupce) vyplněním přihlášky a&nbsp;zaplacením kurzovného uzavírají dohodu o&nbsp;zařazení do&nbsp;kurzu tance na&nbsp;uvedený školní rok podle uvedené specifikace. Lekce taneční přípravky a&nbsp;všestranného pohybového rozvoje v&nbsp;tanečním kurzu pro&nbsp;děti v&nbsp;tanečním sále KC&nbsp;Zlonín.
                            </p>
                            <p className="text-small" data-sal="fade" data-sal-duration="1000">
                                Cena kurzu za&nbsp;pololetí je uvedena na&nbsp;webové stránce Taneční Zlonín. Pro&nbsp;2. a&nbsp;dalšího sourozence sleva&nbsp;20%. Platba převodem nebo v&nbsp;hotovosti nejpozději do&nbsp;druhého tréninku daného pololetí.
                            </p>

                            <p className="text-small" data-sal="fade" data-sal-duration="1000">
                                Na platbu kurzovného je možné na&nbsp;vyžádání vystavit fakturu nebo paragon. Pokud máte zájem o&nbsp;doklad o&nbsp;platbě nebo potvrzení pro&nbsp;pojišťovnu (příspěvek na&nbsp;pohyb), kontaktujte nás na&nbsp;tanecni.zlonin@gmail.com nebo tel./whatsapp +420&nbsp;732&nbsp;560&nbsp;838
                            </p>

                            <p className="text-small" data-sal="fade" data-sal-duration="1000">
                                Přihlašuji závazně své dítě do&nbsp;Taneční Zlonín&nbsp;z.s., a&nbsp;souhlasím s&nbsp;níže specifikovanými podmínkami. Prohlašuji, že&nbsp;si nejsem vědom(a) žádných zdravotních potíží, pro&nbsp;které by mé dítě nemohlo navštěvovat lekce taneční přípravky nebo sportovního tanečního klubu. Souhlasím s&nbsp;tím, aby Taneční Zlonín&nbsp;z.s. zpracovával a&nbsp;evidoval mé osobní údaje a&nbsp;údaje dítěte poskytnuté v&nbsp;souvislosti s&nbsp;členstvím dítěte a&nbsp;činností v&nbsp;Taneční Zlonín&nbsp;z.s. Dále souhlasím s&nbsp;tím, že&nbsp;Taneční Zlonín&nbsp;z.s. je oprávněn poskytovat uvedené osobní údaje do&nbsp;centrální evidence příslušného sportovního svazu. Toto oprávnění vyplývá z&nbsp;organizačních důvodů svazů (identifikace sportovce). Údaje je&nbsp;Taneční Zlonín&nbsp;z.s. oprávněn zpracovávat a&nbsp;evidovat i&nbsp;po&nbsp;ukončení členství v&nbsp;klubu. Prohlašuji, že&nbsp;jsem byl řádně informován o&nbsp;všech skutečnostech dle&nbsp;ustanovení §11 zák.&nbsp;č.&nbsp;101/2000&nbsp;Sb. Prohlašuji, že&nbsp;jsem byl/a seznámen/a s&nbsp;pravidly Taneční Zlonín&nbsp;z.s. a&nbsp;souhlasím s&nbsp;nimi.
                            </p>
                            
                            <p className="text-small" data-sal="fade" data-sal-duration="1000">
                                Poskytovatel (Taneční Zlonín z.s.) se zavazuje poskytovat výuku v&nbsp;objemu a&nbsp;kvalitě v&nbsp;rámci tréninkového plánu pro&nbsp;danou věkovou/výkonnostní skupinu. Poskytovatel si vyhrazuje změnu rozvrhu v&nbsp;souvislosti s&nbsp;pronájmem tanečního sálu. Poskytovatel si vyhrazuje dočasné přerušení výuky ze&nbsp;zdravotních důvodů, či&nbsp;jiných důvodů vyšší moci. Případné přerušení výuky ze&nbsp;strany Poskytovatele bude dle&nbsp;situace doplněno náhradními tréninky.
                                Člen (zákonný zástupce) se zavazuje zaplatit ve&nbsp;stanoveném termínu členský poplatek na&nbsp;příslušné školní pololetí. Zákonný zástupce uděluje souhlas Poskytovateli k&nbsp;pořizování audio/video/foto dokumentace, pořizované během tréninků a&nbsp;dalších aktivit Taneční Zlonín&nbsp;z.s., které mohou být použity k&nbsp;účelům propagace (web, sociální sítě).
                            </p>

                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal open={open} onClose={handleClose}>
                <ModalDialog>
                    <div style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon 
                            icon={faRocket} 
                            size="3x" 
                            style={{ color: '#31aa52', marginTop: '1rem' }} 
                        />
                        <div
                            className="tz-miniheader__h1"
                            style={{ marginBottom: '1rem', maxWidth: '100%', fontSize: '2rem', lineHeight: '2.5rem' }}
                        >
                            Přihláška byla úspěšně odeslána.
                        </div>
                        <Typography>
                            Do emailu Vám posíláme potvrzení.
                        </Typography>
                        <JoyButton onClick={handleClose} variant="outlined" color="primary" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                            Zpět
                        </JoyButton>
                    </div>
                </ModalDialog>
            </Modal>

            <Contact></Contact>
            <Footer></Footer>
        </Layout>
    );
};

export default Content;
