import React from "react"

import "./contact.scss"
import "../../pages/index.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faWaze } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import ImgContact1 from "../../images/contact1.jpg"
import ImgContact2 from "../../images/contact2.jpg"
import ImgContact3 from "../../images/contact3.jpg"
import ImgContact4 from "../../images/contact4.jpg"
import ImgContact5 from "../../images/contact5.jpg"
import ImgContact6 from "../../images/contact6.jpg"

const Contact = () => (

	<div className="tz-contact">

		<div className="container">


			<div className="container-50 tz-contact__container">

				<div>

					<h2 className="mb2 tz-color-black" data-sal="fade" data-sal-duration="1000">Kde nás najdete?</h2>
					<p data-sal="fade" data-sal-duration="1000" className="mb2">Naše taneční kurzy a&nbsp;lekce probíhají na&nbsp;nově zrekonstruovaném tanečním sále Komunitního centra Zlonín. Vedle nádherného tanečního sálu se&nbsp;zázemím je v&nbsp;objektu nově otevřená restaurace <a href="https://zloninskydvur.cz" target="_blank" rel="noreferrer">Zlonínský&nbsp;Dvůr</a>.</p>

					<div className="tz-contact__adress" data-sal="fade" data-sal-duration="1000">
						<h3 className="tz-color-black">Taneční sál KC Zlonín</h3>
						<p className="mt1">
							U Rybníka 1/6<br />
							250 64&nbsp;&nbsp;Zlonín
						</p>
						<a className="tz-socialicon tz-socialicon--googlemaps" href="https://goo.gl/maps/Lepd7bFysr9NmXRd7" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faMapMarkerAlt} /><span className="tz-socialicon__text">Google Maps</span>
						</a>
						<a className="tz-socialicon tz-socialicon--waze" href="https://www.waze.com/cs/live-map/directions/zlonin-1?navigate=yes&amp;to=place.w.9503222.95097758.17929322" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faWaze} /><span className="tz-socialicon__text">Waze</span>
						</a>
					</div>

					<div className="tz-contact__social" data-sal="fade" data-sal-duration="1000">
						<h3><a className="highlight" href="tel:+420732560838">+420 732 560 838</a></h3>
						<h3 className="mb2"><a className="highlight" href="mailto:tanecni.zlonin@gmail.com">tanecni.zlonin@gmail.com</a></h3>
						<a aria-label="Facebook" className="tz-socialicon tz-socialicon--facebook" href="https://www.facebook.com/tanecni.zlonin" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faFacebookF} aria-label="Facebook icon" />
						</a>
						<a aria-label="Instragram" className="tz-socialicon tz-socialicon--instagram ml1" href="https://www.instagram.com/tanecni.zlonin" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faInstagram} aria-label="Instagram icon" />
						</a>
					</div>

				</div>

				<div className="tz-contact__photos" data-sal="fade" data-sal-duration="1000">
					<img src={ImgContact1} alt="Taneční Zlonín" className="tz-contact__photo" />
					<img src={ImgContact2} alt="Taneční Zlonín" className="tz-contact__photo" />
					<img src={ImgContact3} alt="Taneční Zlonín" className="tz-contact__photo" />
					<img src={ImgContact4} alt="Taneční Zlonín" className="tz-contact__photo" />
					<img src={ImgContact5} alt="Taneční Zlonín" className="tz-contact__photo" />
					<img src={ImgContact6} alt="Taneční Zlonín" className="tz-contact__photo" />
				</div>

			</div>

		</div>

	</div>

)

export default Contact